<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <form>
      <base-input
        label="Nome:"
        hint="Insira o nome da margem"
        v-model="margin_.name"
      >
      </base-input>
      <base-input
        label="Margem:"
        hint="0.0%"
        type="money"
        v-model="margin_.margin"
      >
      </base-input>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button type="text" class="button" @click="save">Salvar</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";

export default {
  props: ["margin", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      margin_: { ...(this.margin || {}) },
      isLoadingSave: false,
      systemActions: [],
    };
  },
  watch: {
    margin(v) {
      this.margin_ = { ...(v || {}) };
    },
  },
  computed: {
    IsNew() {
      return !this.margin;
    },
    ModalTitle() {
      return this.IsNew ? "Cadastrar margem" : "Editar margem";
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    save() {
      this.isLoadingSave = true;
      return this.margin ? this.updateMargin() : this.createMargin();
    },
    createMargin() {
      fetch(`${this.$store.state.apiUrl}margins`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(this.margin_),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            this.shouldShow = false;
            this.route_ = {};
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao cadastrar a margem de lucro",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
        });
    },
    updateMargin() {
      fetch(`${this.$store.state.apiUrl}margins/${this.margin_.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify({ ...this.margin_ }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao atualizar a margem de lucro",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
          this.shouldShow = false;
        });
    },
  },
  name: "UserGroupModal",
};
</script>
<style scoped>
.el-autocomplete {
  margin-top: 5px !important;
  display: block !important;
}
</style>