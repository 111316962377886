<template>
  <el-card shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="start">
        <h4>Margens de Lucro</h4>
        <el-col :md="15">
          <el-row type="flex" justify="end">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="medium"
              @click="openMarginModal(null)"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="margins"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="name" label="nome"> </el-table-column>
      <el-table-column
        label="margem"
        :formatter="(r) => `${r?.margin?.replace('.', ',')} %`"
      ></el-table-column
      ><el-table-column label="ações" :width="100">
        <template #default="cat">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openMarginModal(cat.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteMargin(cat.row)"
                title="
              Remover margem?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <margin-modal
      :showModal="showMarginModal"
      :margin="margin"
      @close-modal="showMarginModal = false"
      @should-update="fetchMargins"
    ></margin-modal>
  </el-card>
</template>

<script>
import MarginModal from "./modals/Margin.vue";
export default {
  name: "PricesTable",
  components: { MarginModal },
  data: () => ({
    hasError: false,
    isLoading: false,
    margin: null,
    showMarginModal: false,
    margins: null,
  }),
  computed: {
    ShowProducts() {
      return (
        this?.category?.products.map((p) => ({ ...p, price: p.price || {} })) ||
        []
      );
    },
    CategoryName() {
      return this.category?.name || "";
    },
  },
  mounted() {
    this.fetchMargins();
  },
  methods: {
    ShowProductPrices(p) {
      return p || "";
    },
    openMarginModal(v) {
      this.margin = v;
      this.showMarginModal = true;
    },
    fetchProductCategories(query, callback) {
      fetch(`${this.$store.state.apiUrl}categories`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => callback(json))
        .catch(() => callback([]));
    },
    selectCategory(c) {
      this.prices = this?.category?.products?.reduce((t, e) => {
        t[e.uid] = { name: e.name, uid: e.uid, ...e.price };
        return t;
      }, {});
      this.category = c;
    },
    fetchMargins() {
      fetch(`${this.$store.state.apiUrl}margins`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.margins = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
    deleteMargin(margin) {
      fetch(`${this.$store.state.apiUrl}margins/${margin.uid}`, {
        credentials: "include",
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(
          () => {
            this.fetchMargins();
            this.$notify({
              title: "Margem removida com sucesso",
              type: "success",
              position: "bottom-right",
            });
          },
          (e) => {
            e.then((e) =>
              this.$notify({
                title: "Não foi possível remover a margem",
                type: "error",
                message: e.message,
                position: "bottom-right",
              })
            );
          }
        );
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
.el-button {
  margin-left: 10px;
}
.el-autocomplete {
  margin-top: 5px !important;
  display: block !important;
}
</style>